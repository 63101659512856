<template>
  <div class="text-dark">
    <b-modal id="modal-response" v-model="modalVisible" centered class="p-3">
      <div slot="modal-header" class="d-flex align-items-center justify-content-between p-2 w-100">
        <span class="h4 mb-0 text-primary">Rincian Pembayaran</span>
        <span @click="modalVisible = false" class="h4 mb-0 text-primary text-hover">x</span>
      </div>
      <div class="d-flex align-items-center justify-content-between p-2">
        <span>Total Harga</span>
        <span style="color: #F5831F;">Rp.{{ toCurrency(subTotal) }}</span>
      </div>
      <div v-if="loyaltyUsage && loyaltyUsage !== 0" class="d-flex align-items-center justify-content-between p-2">
        <span>Coin yang digunakan</span>
        <span style="color: #000000;">- {{ toCurrency(loyaltyUsage) }}</span>
      </div>
      <div slot="modal-footer" class="d-flex align-items-center justify-content-between w-100 p-2">
        <span>Total Tagihan</span>
        <span style="color: #F5831F;">Rp.{{ data.total }}</span>
      </div>
    </b-modal>
    <h2 class="mb-5">{{ title }}</h2>
    <div class="card p-3 align-items-center">
      <div v-if="data.payment_type === 'EWALLET'" class="d-flex flex-column align-items-center">
        <!-- {{ data.payment_method.qr_code}} -->
        <span>{{ $t('purchase.detail.detail_transaction_qr') }}</span>
        <img :src="data && data.payment_method && data.payment_method.qr_code" width="225.99px" />
        <!-- {{data.payment_method.qr_code}} -->
      </div>
      <div class="mb-3">
        <span class="mr-1 font-weight-normal">Pembayaran Anda akan berakhir pada</span>
        <span class="font-weight-bold">{{ data && data.expired }}</span>
      </div>
      <div class="card-header mb-2 text-primary" style="background-color: #F4F4F4;">
        <div>
          <span class="h1">{{ time }}</span>
          <!-- <span class="h1">{{ countDown }}</span> -->
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <span>Jam</span>
          <span>Menit</span>
          <span>Detik</span>
        </div>
      </div>
      <div class="card-body col">
        <div class="d-flex mb-5" style="gap: 0px 20px">
          <div class="d-flex align-items-center">
            <CreditIcon />
            <div class="ml-2" style="color: #015CA1">Credit Limit</div>
          </div>
          <span>- Rp. {{ toCurrency(grossTotal) }}</span>
        </div>
        <div class="mb-4">
          <img v-if="data.payment_type !== 'EWALLET'" :src="data.payment_image"
            :alt="data.payment_image && data.payment_image" width="148px" />
        </div>
        <div class="d-flex justify-content-between mb-4">
          <div class="d-flex flex-column flex-column">
            <!-- {{data}} -->
            <span v-if="data.payment_type === 'BANK_TRANSFER'">Bank Transfer</span>
            <span v-else-if="data.payment_type === 'EWALLET'">{{ data.payment_name }}</span>
            <span v-else>Nomor Virtual Account</span>
            <div v-if="data.payment_type !== 'EWALLET'" class="flex-row d-flex align-items-center">
              <span class="se">{{ data && data.payment_method && data.payment_method.account_number }}</span>
              <a class="copy-text ml-1 mt-1"
                v-clipboard:copy="data && data.payment_method && data.payment_method.account_number"
                v-clipboard:success="onCopy" v-clipboard:error="onError" style="width: 50px">
                <a-col :xs="6" :sm="6" :md="6">
                  <copy-icon />
                </a-col>
                <span>salin</span>
              </a>
            </div>
          </div>
          <div class="d-flex flex-column">
            <span>Total Pembayaran</span>
            <div class="d-flex align-items-center justify">
              <span style="color: #F5831F;">Rp.{{ data.total }}</span>
              <a class="copy-text ml-1 mt-1" v-clipboard:copy="data.total" v-clipboard:success="onCopy"
                v-clipboard:error="onError" style="width: 50px">
                <a-col :xs="6" :sm="6" :md="6">
                  <copy-icon />
                </a-col>
                <span>salin</span>
              </a>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-end">
            <span class="text-hover text-primary" @click="toggleModal">Lihat Rincian Pembayaran</span>
          </div>
        </div>
        <div v-if="data && data.instructions && data.instructions.list">
          <span style="color: #015CA1;" class="hover">Instruksi Pembayaran</span>
          <template>
            <a-collapse class="my-3 border-0 text-dark bg-white" expand-icon-position="right" :activeKey='activeKey' accordion>
              <a-collapse-panel :key="key" class="text-dark" :header="title"
                v-for="({ title, description }, key) in (data.instructions.list || (data.instructions.list) || {})" >
                <template #extra>
                  <img :src="data.payment_image" width="60px" class="mr-4" />
                </template>
                <ol v-for="(data, i) in description" :key="i"
                  class="text-secondary d-flex flex-row align-items-center mb-0">
                  {{ i + 1 }}.<li class="border-0 list-group-item" v-html="data"></li>
                </ol>
              </a-collapse-panel>
            </a-collapse>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import CopyIcon from '@/components/Icons/Copy.vue'
import CreditIcon from '@/components/Icons/Credit.vue'

export default {
  name: 'PaymentMixCredit',
  components: {
    CopyIcon,
    CreditIcon,
  },
  props: ['loyaltyUsage', 'subTotal', 'title', 'grossTotal'],
  data: function () {
    return {
      id_params: this.$route.params.order_id,
      data: {},
      activeKey: '',
      modalVisible: false,
      countDown: moment().format('HH:mm:ss'),
    }
  },
  computed: {
    time: function () {
      const time = this.countDown
      const resultTime = moment.utc(time).format('HH:mm:ss')
      return this.countDown <= 0 ? '00:00:00' : resultTime
    },
  },
  methods: {
    getDifferenceInHours(expiredDate, currentDate) {
      const differentTime = expiredDate - currentDate
      const checkIfExpired = differentTime <= 0 ? 0 : moment(expiredDate) - moment(currentDate)
      // const result = moment.utc(checkIfExpired).format('HH:mm:ss')
      return checkIfExpired
    },
    getOrderDetail() {
      this.$store.dispatch('purchase/GETINTRUCTIONSPAYMENT', {
        channel_id: this.$store.state.user.user_data.channel_id,
        order_id: this.id_params,
      })
        .then(({ data }) => {
          const splitDate = data.expired_at.split(' ')
          const endDate = {
            day: moment(splitDate[0]).format('dddd'),
            date: moment(splitDate[0]).format('DD MMMM YYYY'),
            hour: moment(splitDate[1], 'HH:mm:ss').format('HH:mm'),
            countDown: [splitDate[0], splitDate[1]].join(' '),
          }
          const expiredDate = moment(endDate.countDown).format('YYYY-MM-DD HH:mm:ss')
          const hours = this.getDifferenceInHours(new Date(expiredDate).getTime(), new Date().getTime())
          this.countDown = hours
          const newInstructions = (data && data.instructions) || (data && data.instructions && data.instructions.instructions) || {}
          return this.$store.dispatch('purchase/GETINVOICEDETAIL', {
            order_id: this.id_params,
          }).then((res) => {
            const result = res.data
            this.data = {
              ...data,
              ...result,
              newInstructions,
              expired: `${endDate.day}, ${endDate.date} - ${endDate.hour}`,
              total: Intl.NumberFormat('en-US').format(result.gross_amount),
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    toggleModal() {
      this.modalVisible = !this.modal
    },
    onCopy() {
      return this.$notification.success({
        message: 'Copy to Clipboard Success',
      })
    },
    onError() {
      return this.$notification.error({
        message: 'Copy to Clipboard Failed',
      })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  mounted() {
    this.getOrderDetail()
    setInterval(() => {
      this.countDown = moment(this.countDown).subtract(1, 'seconds')
    }, 1000)
  },
}
</script>

<style>
.ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.text-hover:hover {
  color: #015CA1;
  cursor: pointer;
}
</style>
